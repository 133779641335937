import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import Assets from 'containers-spatial/Assets';
import Roads from 'containers-spatial/Roads';
import CreateRoadDetail from 'containers-spatial/Roads/CreateRoadDetail';
import RoadDetail from 'containers-spatial/Roads/RoadDetail';
import LevelCrossings from 'containers-spatial/LevelCrossings';
import CreateLevelCrossing from 'containers-spatial/LevelCrossings/CreateLevelCrossing';
import LevelCrossing from 'containers-spatial/LevelCrossings/LevelCrossing';
import RoadManagement from 'containers-spatial/RoadManagement';
import ManageAsset from 'containers-spatial/Assets/ManageAsset';
import ManageAssetDetailAratRefVehicle from 'containers-spatial/Assets/ManageAssetDetailAratRefVehicle';
import ManageAssetDetailAratTestVehicle from 'containers-spatial/Assets/ManageAssetDetailAratTestVehicle';
import NewAssetDetailAratRefVehicleGateway from 'containers-spatial/Assets/NewAssetDetailAratRefVehicleGateway';
import NewAssetDetailAratTestVehicleGateway from 'containers-spatial/Assets/NewAssetDetailAratTestVehicleGateway';
import NewAssetGateway from 'containers-spatial/Assets/NewAssetGateway';
import AssetPeakReferenceVehicle from 'containers-spatial/Assets/AssetPeakReferenceVehicle';
import AssetPeakHistoricalVehicle from 'containers-spatial/Assets/AssetPeakHistoricalVehicle';

export default {
  routes: [
    RoadManagement,
    Roads,
    Assets,
    LevelCrossings,
    CreateRoadDetail,
    RoadDetail,
    CreateLevelCrossing,
    LevelCrossing,
    ManageAsset,
    NewAssetDetailAratRefVehicleGateway,
    ManageAssetDetailAratRefVehicle,
    NewAssetDetailAratTestVehicleGateway,
    ManageAssetDetailAratTestVehicle,
    NewAssetGateway,
    AssetPeakReferenceVehicle,
    AssetPeakHistoricalVehicle,
  ],
  path: '/access',
  menuTitle: 'Roads and assets',
  menuDescription: 'Manage heavy vehicle public road infrastructure',
  showChildRoutesInMenu: true,
  menuIcon: LocationOnOutlined,
};
