import React from 'react';

import {Alert as MuiAlert, AlertProps as MuiAlertProps} from '@mui/material';

import {DSL_RestrictedVisualProps} from '../../types';

export interface DSL_BaseAlertProps
  extends Omit<MuiAlertProps, 'elevation' | DSL_RestrictedVisualProps> {
  noMargin?: boolean;
  internal_sx?: MuiAlertProps['sx'];
}

export const DSL_BaseAlert = ({
  variant = 'standard',
  noMargin,
  internal_sx,
  ...alertProps
}: DSL_BaseAlertProps) => {
  return (
    <MuiAlert
      sx={{
        ...(noMargin && {
          margin: 0,
        }),
        ...internal_sx,
      }}
      elevation={0}
      variant={variant}
      {...alertProps}
    />
  );
};

export {
  //
  /** @deprecated use DSL_BaseAlertProps instead*/
  DSL_BaseAlertProps as BaseAlertProps, //
  /** @deprecated use DSL_BaseAlert instead*/
  DSL_BaseAlert as BaseAlert,
};
