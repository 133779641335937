export enum VehicleNetworkMapStorageKeys {
  NATIONAL_MAPS_TERMS_ACCEPTED = 'natMap_TermsAccepted',
  STORED_NETWORK_VIEWS = 'natMap_NetworkViews',
  RECENTLY_VIEWED_NETWORKS = 'natMap_RecentlyViewed',
  FAVOURITE_VIEWS = 'natMap_FavouriteViews',
  NATMAP_SILENT_NETWORKSHARE = 'natMap_silent_networkShare',
  NATMAP_FILTERS = 'natMap_filters',
  NATMAP_SUBSCRIBER_EMAIL = 'natMap_subscriber_email',
}

export enum SSOLoginStorageKeys {
  RETURN_URL_PATH = 'return_url_path',
  RETURN_URL_CLIENT_ID = 'return_url_client_id',
}
